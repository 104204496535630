import { useCallback, useEffect, useState } from 'react'
import { getRandomWord, getWordOfDay, isWinningWord, isWordInWordList } from '../lib/words'
import { loadGameStateFromLocalStorage, saveGameStateToLocalStorage } from '../lib/localStorage'
import { addStatsForCompletedGame, loadStats } from '../lib/stats'

function useWordle() {

  const [solutionConfig, setSolutionConfig] = useState({ ...getWordOfDay(), mode: 'day' });

  const [gameState, setGameState] = useState('active')

  const reloadBoard = useCallback(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solutionConfig.solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solutionConfig.solution)
    if (gameWasWon) {
      setGameState('won')
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setGameState('lost')
    }
    return loaded.guesses
  }, [solutionConfig]);

  const [currentGuess, setCurrentGuess] = useState('')
  const [guesses, setGuesses] = useState<string[]>(reloadBoard)
  const [stats, setStats] = useState(() => loadStats())
  const [isNotEnoughLettersAlertOpen, setIsNotEnoughLettersAlertOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)

  const resetBoard = useCallback(() => {
    setCurrentGuess('')
    setGuesses([])
    setGameState('active')
    setIsWordNotFoundAlertOpen(false)
    setStats(loadStats)
  }, []);

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution: solutionConfig.solution })
  }, [guesses, solutionConfig.solution])

  const onEnter = () => {
    if (!(currentGuess.length === 5) && gameState === 'active') {
      setIsNotEnoughLettersAlertOpen(true)
      return setTimeout(() => {
        setIsNotEnoughLettersAlertOpen(false)
      }, 2000)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, 2000)
    }

    const winningWord = isWinningWord(solutionConfig.solution, currentGuess)

    if (currentGuess.length === 5 && guesses.length < 6 && gameState === 'active') {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setGameState('won')
      }

      if (guesses.length === 5) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setGameState('lost')
      }
    }
  }

  const onChar = (value: string) => {
    if (currentGuess.length < 5 && guesses.length < 6 && gameState === 'active') {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const switchSolutionConfig = () => {
    setSolutionConfig(solutionConfig => {
      if (solutionConfig.mode === 'day') {
        return { ...getRandomWord(), mode: 'random' }
      }
      return { ...getWordOfDay(), mode: 'day' }
    })
    resetBoard()
  }

  const chooseNewWord = () => {
    setSolutionConfig({ ...getRandomWord(), mode: 'random' })
    resetBoard()
  }

  return { gameState, guesses, currentGuess, stats, solutionConfig, isNotEnoughLettersAlertOpen, isWordNotFoundAlertOpen, onEnter, onChar, onDelete, chooseNewWord, switchSolutionConfig };
}

export default useWordle;