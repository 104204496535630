import { WORDS } from '../constants/wordlist'

export const isWordInWordList = (word: string) => {
  return WORDS.includes(word.toLowerCase())
}

export const isWinningWord = (solution: string, word: string) => {
  return solution === word
}

export const getRandomWord = () => {
  const index = Math.floor(Math.random() * WORDS.length);
  return {
    solution: WORDS[index].toUpperCase(),
    solutionIndex: index,
  }
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = 1641013200000
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)

  return {
    solution: WORDS[index].toUpperCase(),
    solutionIndex: index,
  }
}
